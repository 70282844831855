import { useEffect, useState } from "react";
import { WhiskyLayout } from "./WhiskyLayout";

import OpenBtn from "./img/open.png";

import OrderIcon from "./img/ReceiveIcon/icons8-장바구니로드에게 1.svg";
import PreparationIcon from "./img/ReceiveIcon/icons8-재고-판매 1.svg";
import CarryIcon from "./img/ReceiveIcon/icons8-트롤리로-이동 1.svg";
import ArriveIcon from "./img/ReceiveIcon/icons8-비행기-이륙 1.svg";
import CompleteIcon from "./img/ReceiveIcon/icons8-팔다 1.svg";
import { DisplayPickupInfoModal } from "./Modal/DisplayPickupInfoModal";
import { jwtDecode } from "jwt-decode";
import { client } from "../../client";
import { GET_EXCHANGERATE, GET_ORDERS } from "./Query/Whisky.gql";

export const MyPage = () => {
  const [userNickname, setUserNickname] = useState(null);
  const [userId, setUserId] = useState(null);
  const [data, setData] = useState(null);
  const [exchangeRate, setExchangeRate] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("accessToken");

    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setUserNickname(decodedToken.nickname);
        setUserId(decodedToken.id);
      } catch (error) {
        console.error("Failed to decode token", error);
      }
    }
  }, []);

  useEffect(() => {
    if (userId) {
      const fetchData = async () => {
        try {
          const res = await client.query({
            query: GET_ORDERS,
            variables: {
              userid: parseInt(userId),
            },
            fetchPolicy: "no-cache",
          });

          const orders = res.data.getOrders.map((order) => ({
            orderDate: order.regist_date,
            img_url: order.image,
            productName: order.goods_name,
            price: order.settleprice,
            price_kor: parseInt(
              order.settleprice * exchangeRate
            ).toLocaleString(),
            departureDate: `${order.d_date} ${order.d_time}:${order.d_minute}`,
            orderNumber: order.order_seq,
            quantity: "",
            status: "",
          }));

          setOrderHistory(orders);

          console.log(res.data.getOrders);
        } catch (err) {
          console.error("failed to fetch Data", err);
        }
      };

      fetchData();
    }
  }, [userId]);

  const [orderHistory, setOrderHistory] = useState(null);
  const [receiptBtnClick, setReceiptBtnClick] = useState(true);
  const [isDeliveryBtnClick, setIsDeliveryBtnClick] = useState(false);
  const [isReturnBtnClick, setIsReturnBtnClick] = useState(false);
  const [pickupModalOpen, setPickupModalOpen] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    const getExchangeRateFromCookie = () => {
      const cookies = document.cookie
        .split("; ")
        .find((row) => row.startsWith("exchangeRate="));
      return cookies ? cookies.split("=")[1] : null;
    };

    const exchangeRateFromCookie = getExchangeRateFromCookie();
    if (exchangeRateFromCookie) {
      setExchangeRate(exchangeRateFromCookie);
    }
  }, []);

  const handlePickupModal = () => {
    setPickupModalOpen(!pickupModalOpen);
  };

  const procedure = [
    {
      title: "01.주문완료",
      image: OrderIcon,
      content: "주문 및 결제가 완료된 단계",
      complete: orderHistory?.length > 0 ? true : false,
    },
    {
      title: "02.상품준비",
      image: PreparationIcon,
      content: "상품을 준비하는 단계",
      complete: false,
    },
    {
      title: "03.상품이동",
      image: CarryIcon,
      content: "상품을 공항으로 발송한 단계",
      complete: false,
    },
    {
      title: "04.인도장 도착",
      image: ArriveIcon,
      content: "상품이 공항에 도착한 단계",
      complete: false,
    },
    {
      title: "05.인도완료",
      image: CompleteIcon,
      content: "상품이 고객님께 인도된 단계",
      complete: false,
    },
  ];

  return (
    <WhiskyLayout
      pageName={"마이페이지"}
      menu={true}
      setIframeUrl={null}
      iframe={null}
    >
      <section className="h-[80px] py-1 flex items-center px-3">
        <figure className="w-[60px] h-[60px]  flex items-center justify-center mr-1">
          <img
            src="https://placehold.co/44x44"
            alt="img"
            className="w-[50px] h-[50px] rounded-[50%]"
          />
        </figure>
        <div>
          <p>{userNickname} 님</p>
        </div>
      </section>
      <div className="h-[10px] bg-DividerGray"></div>
      <section className="px-3 min-h-[55vh]">
        <section className="flex justify-between items-center">
          <h1 className="h-[54px] flex items-center text-[16px] font-bold leading-[19px]">
            주문내역
          </h1>
          <button
            onClick={handlePickupModal}
            className="text-[14px] text-Gray70 py-1 px-2 h-fit border-solid border-[1px] border-BorderGray"
          >
            인도장 위치 안내
          </button>
        </section>

        {pickupModalOpen && (
          <DisplayPickupInfoModal onClose={handlePickupModal} />
        )}

        {orderHistory?.length > 0 ? (
          <article className="bg-backgroundGray">
            <h1 className="text-[16px] font-bold leading-[19px] pt-[21px] pb-[10px]">
              총 {orderHistory.length}건
            </h1>
            {orderHistory.map((order, index) => {
              return (
                <div className="bg-white mx-1 mt-3 mb-5" key={index}>
                  <div className="px-3 py-2">
                    <section className="flex justify-between items-center pb-1 pt-4">
                      <span className="text-center text-[14px] text-Gray70">
                        주문일: {order.orderDate}
                      </span>
                      <button
                        onClick={() => {
                          window.location.href = "https://m.busandf.com/mypage";
                        }}
                        className="text-[14px] text-Gray70 py-1 px-2 border-solid border-[1px] border-BorderGray"
                      >
                        주문취소
                      </button>
                    </section>
                    <section className="text-[16px] font-bold pt-1">
                      <div className="flex items-center justify-start">
                        <img
                          src={order.img_url}
                          alt={order.productName}
                          className="w-[60px] h-[60px]"
                        />
                        <figure className="ml-2">
                          <span className="line-clamp-2">
                            {order.productName}
                          </span>
                          <div className="text-[15px]">
                            <span className="mr-1">${order.price}</span>
                            <span className="font-normal">
                              ({order.price_kor.toLocaleString()}원)
                            </span>
                          </div>
                        </figure>
                      </div>
                    </section>
                    <div className="bg-BorderGray w-full h-[1px] my-3"></div>
                    <section>
                      <div className="flex justify-between items-center first:pb-2 first:py-0 py-2">
                        <span className="text-center text-[14px] text-Gray70">
                          출국일시
                        </span>
                        <span className="text-center text-[15px] text-black">
                          {order.departureDate}
                        </span>
                      </div>
                      <div className="flex justify-between items-center first:pb-2 first:py-0 py-2">
                        <span className="text-center text-[14px] text-Gray70">
                          주문번호
                        </span>
                        <span className="text-center text-[15px] text-black">
                          {order.orderNumber}
                        </span>
                      </div>
                      <div className="flex justify-between items-center first:pb-2 first:py-0 py-2">
                        <span className="text-center text-[14px] text-Gray70">
                          상품수량
                        </span>
                        <span className="text-center text-[15px] text-black">
                          {order.quantity}개
                        </span>
                      </div>
                      {/* <div className="flex justify-between items-center first:pb-2 first:py-0 py-2">
                        <span className="text-center text-[14px] text-Gray70">
                          진행상태
                        </span>
                        <span className="text-center text-[15px] text-black">
                          {order.status}
                        </span>
                      </div> */}
                    </section>
                  </div>
                </div>
              );
            })}
          </article>
        ) : (
          <article className="h-full flex items-center justify-center">
            <span className="text-[14px] leading-[16px] font-normal text-Gray70">
              주문 내역이 없습니다
            </span>
          </article>
        )}
      </section>

      <section className="h-[54px] flex items-center px-3 border-y-[1px] border-solid border-Gray10 justify-between">
        <span className="font-bold text-[16px] leading-[19px] w-1/3">
          상품수령절차
        </span>
        <button
          className="w-2/3 flex justify-end h-[54px] items-center"
          onClick={() => {
            setReceiptBtnClick(!receiptBtnClick);
          }}
        >
          <img
            src={OpenBtn}
            alt="open"
            className={!receiptBtnClick ? "rotate-180" : ""}
          />
        </button>
      </section>
      {receiptBtnClick && (
        <section className="px-3 h-[492px] bg-backgroundGray grid gap-[2px]">
          {procedure.map((phase, index) => {
            return (
              <article
                className="h-[84px] bg-white w-full last:mb-[20px] first:mt-[20px]"
                key={index}
              >
                <section className="flex w-full h-full items-center mx-[20px]">
                  <div className="w-[50px] h-[50px] flex items-center justify-center mr-[12px]">
                    <img src={phase.image} alt="img" />
                  </div>
                  <div className="w-[200px]">
                    <h1 className="text-[14px] leading-[15px] font-bold mb-1">
                      {phase.title}
                    </h1>
                    <p className="text-[13px] text-Gray70">{phase.content}</p>
                  </div>
                  {phase.complete ? (
                    <div className="flex items-center justify-end w-[50px] ml-8">
                      {" "}
                      {/* 8단위의 마진 추가 */}
                      <img
                        src="https://dfx-img.s3.ap-northeast-2.amazonaws.com/checked_whisky.png"
                        alt="Checked"
                        className="w-[20px] h-[20px]"
                      />
                    </div>
                  ) : (
                    <div className="flex items-center justify-end w-[50px] ml-8">
                      {" "}
                      {/* 동일하게 마진 추가 */}
                      <img
                        src="https://dfx-img.s3.ap-northeast-2.amazonaws.com/unchecked_whisky.png"
                        alt="Unchecked"
                        className="w-[20px] h-[20px]"
                      />
                    </div>
                  )}
                </section>
              </article>
            );
          })}
        </section>
      )}

      <section className="h-[54px] flex items-center px-3 border-y-[1px] border-solid border-Gray10 justify-between">
        <span className="font-bold text-[16px] leading-[19px] w-1/3">
          인도안내
        </span>
        <button
          className="w-2/3 flex justify-end h-[54px] items-center"
          onClick={() => {
            setIsDeliveryBtnClick(!isDeliveryBtnClick);
          }}
        >
          <img
            src={OpenBtn}
            alt="open"
            className={!isDeliveryBtnClick ? "rotate-180" : ""}
          />
        </button>
      </section>
      {isDeliveryBtnClick && (
        <section className="px-3 bg-backgroundGray h-[405px]">
          <article>
            <ul className="text-[12px] leading-[16px] mx-2 list-disc list-inside grid gap-4 text-Gray70">
              <li className="mt-5 pl-[1em] -indent-[1.4em]">
                반드시 지정된 보세구역(공항 및 항만 인도장)에서 상품을
                수령하셔야 합니다.
              </li>
              <li className="pl-[1em] -indent-[1.4em]">
                출입국 당일 인도장에 실물여권을 제시해 주시면 상품을 인도받을 수
                있습니다.
              </li>
              <li className="font-black text-black pl-[1em] -indent-[1.4em]">
                다음의 사항에 해당되는 경우 출국 시 공항에서 상품 수령이
                불가능하오니, 반드시 확인 부탁드립니다.
                <ul className="pl-1 font-normal text-Gray70">
                  <li className="mb-2 mt-3">
                    1) 구 여권번호 또는 잘못 입력하신 여권번호로 주문한 경우
                  </li>
                  <li className="my-2">
                    2) 주문서에 기재된 영문 이름과 출국 시 여권상 영문 이름의
                    한글자라도 다를 경우
                  </li>
                  <li className="my-2">
                    3) 출입국 정보가 실제와 다른 경우 <br /> -출국일/입국일,
                    출발지/도착지, 편명(경유지)
                  </li>
                </ul>
              </li>
              <li>
                여권정보 또는 출입국일 변경을 원하시는 경우 ‘마이페이지’에서
                변경하시거나, 출국 3일전까지 고객센터로 연락주셔야 상품 수령이
                가능합니다.
              </li>
              <li>
                단, 출입국일 변경은 교환권별 진행상태가 ‘주문완료’ 또는
                ‘상품준비’일 경우에만 가능합니다.
              </li>
            </ul>
          </article>
        </section>
      )}

      <section className="h-[54px] flex items-center px-3 border-y-[1px] border-solid border-Gray10 justify-between">
        <span className="font-bold text-[16px] leading-[19px] w-1/2">
          주문취소/반품안내
        </span>
        <button
          className="w-1/2 flex justify-end h-[54px] items-center"
          onClick={() => {
            setIsReturnBtnClick(!isReturnBtnClick);
          }}
        >
          <img
            src={OpenBtn}
            alt="open"
            className={!isReturnBtnClick ? "rotate-180" : ""}
          />
        </button>
      </section>
      {isReturnBtnClick && (
        <section className="px-3 bg-backgroundGray overflow-hidden h-[405px] transition-all ease-in-out transform opacity-0 max-h-0 animate-expand">
          <article>
            <ul className="text-Gray70 text-[12px] leading-[16px] mx-2 list-disc list-inside grid gap-2">
              <li className="mt-5 pl-[1em] -indent-[1.4em]">
                주문취소를 원하시는 경우 주문 처리 절차에 따라 아래와 같습니다.
                <ul className="pl-1 font-normal">
                  <li className="mb-2 mt-3">
                    1) 1단계 주문완료, 2단계 상품준비 <br /> -’부산면세점 사이트
                    마이페이지 &gt; 주문내역 &gt; 주문취소’에서 직접 취소 가능
                  </li>
                  <li className="my-2">
                    2) 3단계 상품이동, 4단계 인도장 도착 <br /> -’부산면세점
                    사이트 마이페이지 &gt; 주문내역 &gt; 주문취소’에서 취소 신청
                    가능
                  </li>
                </ul>
              </li>
              <li className="pl-[1em] -indent-[1.4em]">
                출입국 시 상품을 인도하지 못하였거나, 일부 예외적인 경우 1:1상담
                또는 고객센터로 연락주시기 바랍니다.
              </li>
              <li className="pl-[1em] -indent-[1.4em]">
                사은품이 지급된 상품은 전체 취소만 가능합니다.
              </li>
              <li className="pl-[1em] -indent-[1.4em]">
                지점 구매 상품의 취소 문의는 아래로 문의주시기 바랍니다. <br />
                -고객센터 (051-460-1950)
              </li>
              <li className="pl-[1em] -indent-[1.4em]">
                반품은 상품을 수령한 날로부터 15일 이내에 청약 철회가
                가능합니다.
              </li>
            </ul>
          </article>
        </section>
      )}
    </WhiskyLayout>
  );
};
