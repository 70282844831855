import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  SignUpInputItemText,
  SignUpContainer,
  SignUpInputListContainer,
  SignUpInputItem,
} from "../../../style/Login.module";

import { useNavigate } from "react-router-dom";
import { client } from "../../../client";
import { SIGN_UP_BSDF } from "../Query/Whisky.gql";

export const SignUpBsdf = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [name, setName] = useState("");
  const [nickname, setNickname] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [isMale, setIsMale] = useState(false);

  const [confirmPassword, setConfirmPassword] = useState("");

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-={}\[\]:;"'<>?,./])?[a-zA-Z\d!@#$%^&*()_+\-={}\[\]:;"'<>?,./]{8,}$/;

  const formatBirthDate = (birthDate) => {
    if (birthDate.length === 8) {
      return `${birthDate.slice(0, 4)}-${birthDate.slice(
        4,
        6
      )}-${birthDate.slice(6, 8)}`;
    }
    return birthDate;
  };

  const handleSignUp = async () => {
    // Redirect to the signup page
    if (!name || !birthDate || !email || !password) {
      toast.error("모든 값을 입력해주세요.");
      return;
    }

    if (!emailRegex.test(email)) {
      toast.error("올바른 이메일 형식을 입력해주세요.");
      return false;
    }

    if (nickname.length < 4) {
      toast.error("별명은 4글자 이상 입력해주세요.");
      return;
    }

    if (birthDate.length !== 8) {
      toast.error("생년월일을 8자리로 입력해주세요. (예: 19990101)");
      return;
    }

    if (phoneNumber.length !== 11) {
      toast.error("핸드폰번호 11자리로 입력해주세요.");
      return;
    }

    if (!passwordRegex.test(password)) {
      toast.error(
        "비밀번호는 대소문자와 숫자를 조합하여 8자리 이상 입력해주세요."
      );
      return false;
    }

    if (password !== confirmPassword) {
      toast.error("비밀번호가 일치하지 않습니다.");
      return;
    }

    const formattedBirthDate = formatBirthDate(birthDate);

    console.log("email", typeof email);
    console.log("password", typeof password);
    console.log("phone_number", typeof phoneNumber);
    console.log("name", typeof name);
    console.log("nickname", typeof nickname);
    console.log("birthDate", typeof formattedBirthDate);
    console.log("isMale", typeof isMale);

    const variables = {
      email: email,
      password: password,
      phone_number: phoneNumber,
      name: name,
      nickname: nickname,
      birth_date: formattedBirthDate,
      is_male: isMale,
    };

    try {
      const res = await client.mutate({
        mutation: SIGN_UP_BSDF,
        variables: {
          input: variables,
        },
      });
      alert("가입 성공");
      navigate("/loginbsdf");
    } catch (error) {
      console.log(error);
      alert(error);
    }
  };

  return (
    <SignUpContainer>
      <h1> 회원가입 </h1>
      <SignUpInputListContainer>
        <SignUpInputItem>
          <SignUpInputItemText> 이름 </SignUpInputItemText>
          <TextField
            sx={{ width: "100%", flexGrow: 1 }}
            id="standard-basic"
            variant="standard"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </SignUpInputItem>
        <SignUpInputItem>
          <SignUpInputItemText> 생년월일 </SignUpInputItemText>
          <TextField
            sx={{ width: "100%", flexGrow: 1 }}
            id="standard-basic"
            placeholder="예: 19990101"
            variant="standard"
            value={birthDate}
            onChange={(e) => setBirthDate(e.target.value)}
          />
        </SignUpInputItem>
        <SignUpInputItem>
          <SignUpInputItemText> 성별 </SignUpInputItemText>
          <FormControl component="fieldset">
            <RadioGroup
              row
              value={isMale}
              onChange={(e) => setIsMale(e.target.value === "true")}
            >
              <FormControlLabel value={true} control={<Radio />} label="남성" />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label="여성"
              />
            </RadioGroup>
          </FormControl>
        </SignUpInputItem>
        <SignUpInputItem>
          <SignUpInputItemText> 이메일 </SignUpInputItemText>
          <TextField
            sx={{ width: "100%", flexGrow: 1 }}
            id="standard-basic"
            placeholder="dutyfree@naver.com"
            variant="standard"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </SignUpInputItem>
        <SignUpInputItem>
          <SignUpInputItemText> 별명 </SignUpInputItemText>
          <TextField
            sx={{ width: "100%", flexGrow: 1 }}
            id="standard-basic"
            placeholder="4자 이상"
            variant="standard"
            value={nickname}
            onChange={(e) => setNickname(e.target.value)}
          />
        </SignUpInputItem>
        <SignUpInputItem>
          <SignUpInputItemText> 핸드폰번호 </SignUpInputItemText>
          <TextField
            sx={{ width: "100%", flexGrow: 1 }}
            id="standard-basic"
            placeholder="01077778888 (11자리)"
            variant="standard"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
        </SignUpInputItem>
        <SignUpInputItem>
          <SignUpInputItemText> 비밀번호 </SignUpInputItemText>
          <TextField
            sx={{ width: "100%", flexGrow: 1 }}
            id="standard-basic"
            variant="standard"
            type="password"
            placeholder="영문 소문자, 대문자, 숫자 조합 8자리 이상"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </SignUpInputItem>
        <SignUpInputItem>
          <SignUpInputItemText>비밀번호 확인</SignUpInputItemText>
          <TextField
            sx={{ width: "100%", flexGrow: 1 }}
            variant="standard"
            type="password"
            placeholder="영문 소문자, 대문자, 숫자 조합 8자리 이상"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </SignUpInputItem>
      </SignUpInputListContainer>

      <Button
        sx={{
          width: "10rem",
          marginTop: "5rem",
          backgroundColor: "#000000",
          "&:hover": {
            backgroundColor: "#00000080", // Replace with your desired hover color
          },
        }}
        variant="contained"
        onClick={handleSignUp}
      >
        가입하기
      </Button>
      <ToastContainer />
    </SignUpContainer>
  );
};
