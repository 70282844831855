export const PurchaseModal = ({ onClose, purchaseUrl, setIframeUrl }) => {
  const confirm = async () => {
    await onClose();
    setIframeUrl(purchaseUrl);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <section className="z-20 bottom-0 left-0 right-0 font-CustomSans fixed flex items-center justify-center w-full min-w-[320px] sm:left-2">
      <div className="h-[172px] bg-white z-50 w-full max-w-[400px] rounded-t-[20px] shadow-lg relative">
        <div className="mt-[40px] text-center px-8">
          <span className="text-[16px] leading-[23px] font-normal text-pretty">
            인천공항, 김해공항 출국 예정자만 <br /> 출국 전 24시간까지 주문
            가능합니다.
          </span>
        </div>

        <div className="w-full max-h-[50px] flex flex-col items-center absolute bottom-0">
          <button
            onClick={confirm}
            className="bg-black text-white font-semibold py-3 w-full"
          >
            확인
          </button>
        </div>
      </div>

      <div
        className="fixed inset-0 bg-black opacity-50 z-8"
        onClick={onClose}
      ></div>
    </section>
  );
};
