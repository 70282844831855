import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import { gql } from "@apollo/client";
import {
  LoginInputContainer,
  LoginCenterContainer,
  LoginButtinContainer,
} from "../../../style/Login.module";

import { useLocation, useNavigate } from "react-router-dom";
import AgreeModal from "../../../component/sign-up/AgreeModal";
import { client } from "../../../client";

const LOGIN = gql`
  mutation login($email: String, $password: String) {
    login(email: $email, password: $password)
  }
`;

export const LoginBsdf = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggedIn, setIsLoggedin] = useState(false);

  const location = useLocation();

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      setIsLoggedin(true);
      navigate("/");
    }
  }, []);

  const handleLogin = () => {
    // Perform login logic here
    client
      .mutate({
        mutation: LOGIN,
        variables: {
          email: email,
          password: password,
        },
      })
      .then((result) => {
        // Handle successful login
        console.log(result.data);
        alert("로그인성공");
        const accessToken = result.data.login;
        localStorage.setItem("accessToken", accessToken);
        const from = location.state?.from?.pathname || "/";
        navigate(from);
      })
      .catch((error) => {
        // Handle login error
        alert(error);
        console.log(error);
      });
  };

  const handleNaverLogin = () => {
    const naverLoginUrl = "http://localhost:4001/auth/naver/callback";
    window.location.href = naverLoginUrl;
  };

  const handleKakaoLogin = () => {
    const Rest_api_key = "d2020bbf7b3cad61268df5b704d99445"; //REST API KEY
    // const redirect_uri =
    //   "http://dfx-front.s3-website.ap-northeast-2.amazonaws.com"; //Redirect URI
    // const redirect_uri = "http://localhost:4001/auth/kakao/callback"; //Redirect URI
    const redirect_uri = `${process.env.REACT_APP_API_URL.replace(
      /graphql\/?$/,
      ""
    )}auth/kakao/callback`;
    console.log("redirecturi...", redirect_uri);

    // oauth 요청 URL
    const kakaoURL = `https://kauth.kakao.com/oauth/authorize?client_id=${Rest_api_key}&redirect_uri=${redirect_uri}&response_type=code`;
    window.location.href = kakaoURL;
  };

  const handleAgree = () => {
    // Redirect to the signup page
    navigate("/signupbsdf");
  };

  // 모달 관련

  const [open, setOpen] = useState(false);
  const handleSignUp = () => {
    // Open the sign-up modal
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <LoginCenterContainer>
      <h1> 로그인 </h1>

      <LoginInputContainer>
        <TextField
          fullWidth
          label="Email"
          value={email}
          sx={{ width: "100%" }}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          sx={{ marginTop: "1rem" }}
          fullWidth
          label="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </LoginInputContainer>
      <LoginButtinContainer>
        <Button
          sx={{ width: "100%", backgroundColor: "#888" }}
          variant="contained"
          onClick={handleLogin}
        >
          {" "}
          일반 로그인{" "}
        </Button>
        <Button
          sx={{
            width: "100%",
            marginTop: "0.5rem",
            backgroundColor: "#fee102",
            color: "#000000",
            "&:hover": {
              backgroundColor: "#fee10280", // Replace with your desired hover color
            },
          }}
          variant="contained"
          onClick={handleKakaoLogin}
        >
          {" "}
          카카오 로그인{" "}
        </Button>

        <Button
          sx={{
            width: "100%",
            marginTop: "0.5rem",
            backgroundColor: "#03C75A",
            color: "#000000",
            "&:hover": {
              backgroundColor: "#05D67C", // Replace with your desired hover color
            },
          }}
          variant="contained"
          onClick={handleNaverLogin}
        >
          {" "}
          네이버 로그인{" "}
        </Button>

        <Button
          sx={{
            width: "100%",
            marginTop: "2rem",
            backgroundColor: "#000000",
            "&:hover": {
              backgroundColor: "#00000080", // Replace with your desired hover color
            },
          }}
          variant="contained"
          onClick={handleSignUp}
        >
          회원가입
        </Button>
      </LoginButtinContainer>
      <AgreeModal
        open={open}
        handleClose={handleClose}
        handleAgree={handleAgree}
      />
    </LoginCenterContainer>
  );
};
