export const ListModal = ({ onClose }) => {
  return (
    <section className="z-20 left-0 right-0 bottom-0 font-CustomSans fixed flex items-center justify-center w-full min-w-[320px]">
      <div className="h-[320px] bg-white z-50 px-8 rounded-t-[20px] shadow-lg relative max-w-[405px]">
        <h1 className="text-[18px] font-bold leading-[21px] text-center mt-[40px] py-1">
          프리미엄 위스키 한정 수량 특가
        </h1>

        <div className="mt-[25px] text-center">
          <span className="text-[16px] leading-[23px] font-normal text-pretty">
            면세점가격비교 앱 X 부산면세점, <br /> 프리미엄 위스키를 세계
            최저가에 만나보세요. <br />
            빠른 구매 필수!
          </span>
        </div>

        <div className="text-center mb-[30px] mt-[12px] text-[14px] leading-[20px] font-normal text-Gray70">
          *인천공항, 김해공항 출국 예정자만 <br /> 출국 전 24시간까지 주문
          가능합니다.
        </div>

        <div className="w-full max-h-[50px] flex flex-col items-center">
          <button
            onClick={() => onClose()}
            className="bg-black text-white font-semibold py-3 px-12 w-full sm:min-w-[360px]"
          >
            확인
          </button>
        </div>
      </div>

      <div
        className="fixed inset-0 bg-black opacity-50 z-8"
        onClick={onClose}
      ></div>
    </section>
  );
};
