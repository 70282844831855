import Star from './img/star.png';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { useEffect, useState } from 'react';
import { client } from '../../client';
import { GET_REVIEW_BEST } from './Query/Whisky.gql';

export const Review = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [bestReview, setBestReview] = useState(null);
  const [totalReview, setTotalReview] = useState(null);
  const [reviewCount, setReviewCount] = useState(null);

  useEffect(() => {
    const fetchBestReview = async () => {
      try {
        const res = await client.query({
          query: GET_REVIEW_BEST,
          variables: {
            productId: data.id,
          },
          fetchPolicy: 'cache-first',
        });

        setBestReview(res.data.getReviewPage.best_reviews);
        setTotalReview(res.data.getReviewPage.total_reviews);
        setReviewCount(res.data.getReviewPage.best_review_count);
      } catch (err) {
        console.error('failed to fetch', err);
      }
    };

    fetchBestReview();
  }, []);

  useEffect(() => {
    console.log(bestReview);
    console.log(totalReview);
  }, [bestReview]);

  const qualityRatingHandler = (e) => {
    switch (e) {
      case 1:
        return '별로예요';
      case 2:
        return '보통이에요';
      case 3:
        return '최고예요';
    }
  };

  const repurchaseIntentHandler = (e) => {
    switch (e) {
      case 1:
        return '없어요';
      case 2:
        return '보통이에요';
      case 3:
        return '있어요';
    }
  };

  function unixToKoreanDate(unixTime) {
    const date = new Date(parseInt(unixTime));
    return date.toLocaleDateString('ko-KR');
  }

  const handleSwiperChange = (e) => {
    setActiveIndex(e);
  };

  return (
    <div className="mb-12">
      {/* 베스트 리뷰 */}

      <article className="px-3">
        <div className="text-center flex justify-between font-bold text-[16px] leading-[19px] mt-[30px]">
          <span>베스트 리뷰</span>
          <span className="text-[14px] font-normal text-Gray70">
            <span className="font-bold ">
              {reviewCount !== 0 ? activeIndex + 1 : 0}
            </span>
            <span className="mx-1">/</span>
            <span>{reviewCount}</span>
          </span>
        </div>
        {bestReview ? (
          <Swiper
            spaceBetween={10}
            slidesPerView={1}
            centeredSlides={true}
            onActiveIndexChange={(e) => handleSwiperChange(e.realIndex)}
            className="relative"
          >
            {bestReview.map((review, index) => {
              return (
                <SwiperSlide key={index}>
                  <div className=" flex items-center h-[120px] w-full mt-[12px] rounded-[6px] border-[1px] border-ReviewBorder">
                    {review.photos ? (
                      <>
                        <div className="min-w-[80px] m-3">
                          <img
                            src={review.photos[0].url}
                            alt="review"
                            className="w-[80px] h-[80px]"
                          />
                        </div>
                        <div className="h-[80px] my-3 mx-1 text-[14px] font-normal leading-[20px] ">
                          <div className="line-clamp-3 h-3/4">
                            {review.comment}
                          </div>
                          <div className="flex items-center h-1/4">
                            <img src={Star} className="w-[18px] h-[18px]" />{' '}
                            <span>{review.rating}</span>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="max-h-[80px] m-3 text-[14px] font-normal leading-[20px] ">
                        <div className="line-clamp-3">{review.comment}</div>
                        <div className="flex items-center">
                          <img src={Star} className="w-[18px] h-[18px]" />{' '}
                          <span>{review.rating}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        ) : null}
      </article>

      {/* 전체 리뷰 */}
      <article className="max-h-[500px] overflow-hidden">
        <div className="px-3 my-[18px] text-center flex justify-between font-bold text-[16px] leading-[19px] mt-[30px]">
          <span>전체 리뷰</span>
        </div>

        {totalReview ? (
          <>
            {totalReview.map((review, index) => {
              return (
                <div
                  key={index}
                  className="border-b-[1px] rounded border-TotalReviewBorderBottom"
                >
                  <div className="px-3 text-Gray70">
                    <div className="flex my-[15px] text-[14px] justify-between text-center items-center">
                      <div className="flex items-center">
                        <h1>
                          {review.user.nickname ? review.user.nickname : '이름'}
                        </h1>
                        <div className="w-[1px] h-[10px] bg-TotalReviewBorderBottom m-2"></div>
                        <p className="flex items-center">
                          <img src={Star} className="w-[18px] h-[18px]" />
                          <span>{review.rating}</span>
                        </p>
                      </div>
                      <span>{unixToKoreanDate(review.created_at)}</span>
                    </div>

                    <div className="flex text-Gray70 text-[13px] leading-[15px] mb-[14px]">
                      <div className="px-2 py-1 mr-1 rounded-[22px] border-[1px] border-TotalReviewBorderBottom">
                        {qualityRatingHandler(review.quality_score)}
                      </div>
                      <div className="px-2 py-1 mr-1 rounded-[22px] border-[1px] border-TotalReviewBorderBottom">
                        {repurchaseIntentHandler(review.repurchase_score)}
                      </div>
                    </div>

                    <div className="text-black text-[14px] leading-[20px]">
                      <div>{review.comment}</div>
                    </div>

                    {review.photos ? (
                      <div className="flex my-4 overflow-scroll">
                        {review.photos.map((item, index) => {
                          return (
                            <figure key={index} className="mx-[5px]">
                              <img
                                src={item.url}
                                alt="리뷰 사진"
                                className="w-[80px] h-[80px]"
                              />
                            </figure>
                          );
                        })}
                      </div>
                    ) : null}
                  </div>
                </div>
              );
            })}
          </>
        ) : null}
      </article>
    </div>
  );
};
