import React from "react";

import whiskyLogo from "../img/limitedLogo.svg";

export const CompareModal = ({ onClose, data, price_member }) => {
  console.log("데이터", data);

  return (
    <div className=" z-20 font-CustomSans fixed inset-0 flex items-center justify-center">
      <div className="bg-white z-50 px-6 py-10 rounded-[20px] shadow-lg max-w-md w-[370px] relative">
        <div className="text-center">
          <div className=" flex justify-center mb-[30px] mt-[15px]">
            <span className="font-bold text-[18px] leading-[21px]">
              프리미엄 위스키 한정 수량 특가
            </span>
          </div>
          <div>
            <div className="relative text-FontColor items-center font-bold p-4 text-[14px] leading-[16px] flex justify-between my-2 rounded-[8px] h-[56px] text-BSDFFontColor">
              <div className="z-10">DFP X 부산면세점</div>
              <div className="z-10 font-bold"> {price_member} $</div>

              <div className="absolute inset-0 rounded-[8px] px-[3px] py-[2px] bg-gradient-to-r from-[#B346D9] to-[#FF8383] pointer-events-none">
                <div className="bg-white h-full rounded-[8px]"></div>
              </div>
            </div>
            {data.map((item, idx) => {
              return (
                <div key={idx}>
                  <div className="border-solid border-[1px] border-BorderGray items-center p-4 text-[14px] text-black leading-[23px] text-pretty font-normal flex justify-between my-2 rounded-[8px] h-[56px]">
                    <div>
                      {(() => {
                        switch (item.duty_free_site) {
                          case "LOTTE":
                            return <div>롯데 면세점</div>;
                          case "SHILLA":
                            return <div>신라 면세점</div>;
                          case "SSG":
                            return <div>신세계 면세점</div>;
                          default:
                            return null;
                        }
                      })()}
                    </div>
                    <div>{item.price_member.toLocaleString()} $</div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="py-2 rounded-lg mt-[25px] mb-[40px] text-[13px] leading-[20px] font-normal text-Gray70">
            면세점가격비교 앱과 부산면세점의 콜라보로 <br /> 세계 최저가 혜택을
            제공하는 서비스입니다.
          </div>

          <div className="w-full flex flex-col items-center">
            <button
              onClick={() => onClose()}
              className="bg-black text-white font-semibold py-3 px-12  w-full"
            >
              확인
            </button>
          </div>
        </div>
      </div>

      <div
        className="fixed inset-0 bg-black opacity-50 z-2"
        onClick={onClose}
      ></div>
    </div>
  );
};
