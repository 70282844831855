import BackImg from "./img/home.png";
import MyPageImg from "./img/gnb-my.svg";
import whiskyLogo from "./img/logo.svg";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { client } from "../../client";
import { GET_EXCHANGERATE, GET_WHISKY_PRODUCTS } from "./Query/Whisky.gql";
import { ListModal } from "./Modal/ListModal";

export const MerchandiseList = () => {
  const [data, setData] = useState(null);
  const [id, setId] = useState(0);
  const [exchangeRate, setExchangeRate] = useState(null);

  const [isAlertModalOpen, setIsAlertModalOpen] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();

  const handleSwiperChange = (e) => {
    const index = e.activeIndex;
    const activeItem = data[index];

    setId(activeItem.id);
  };

  const handleMypageBtn = () => {
    const accessToken = localStorage.getItem("accessToken");

    if (!accessToken)
      return navigate("/loginbsdf", { state: { from: location } });

    navigate("/mypage");
  };

  const fetchProducts = async () => {
    try {
      const res = await client.query({
        query: GET_WHISKY_PRODUCTS,
        fetchPolicy: "no-cache",
      });

      setData(res.data.getWhiskyProducts);
      setId(res.data.getWhiskyProducts[0].id);
    } catch (err) {
      console.error("fail to load products", err);
    }
  };

  const ModalHandler = () => {
    setIsAlertModalOpen(!isAlertModalOpen);
  };

  useEffect(() => {
    const getExchangeRateFromCookie = () => {
      const cookies = document.cookie
        .split("; ")
        .find((row) => row.startsWith("exchangeRate="));
      return cookies ? cookies.split("=")[1] : null;
    };

    const exchangeRateFromCookie = getExchangeRateFromCookie();
    if (exchangeRateFromCookie) {
      setExchangeRate(exchangeRateFromCookie);
    }
  }, []);

  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const handlePurchase = (e) => {
    window.location.href = `/whisky365/${e}`;
  };

  return (
    <main className="font-CustomSans flex flex-col justify-center items-center bg-gray-50  w-full sm:min-w-[360px] sm:w-screen">
      <div className="bg-backgroundGray w-full max-w-400 shadow-custom sm:min-w-[360px] sm:w-screen">
        <header className="flex flex-col justify-between w-full pb-2 pt-1 pl-3 pr-3 bg-white">
          <nav
            aria-label="header"
            className="flex items-center justify-between h-[60px] mt-2 w-full pr-0"
          >
            <div className="flex items-center justify-between w-full mr-3">
              <div className="w-1/5 pl-1">
                <button
                  className=" flex justify-start"
                  onClick={() => (window.location.href = "/")}
                >
                  <img src={BackImg} alt="뒤로가기" />
                </button>
              </div>
              <div
                className="w-full
               font-pretendard font-semibold text-black text-center flex justify-center whitespace-nowrap"
              >
                <span className="text-[18px] leading-[20px] flex items-center">
                  프리미엄 위스키 한정 수량 특가
                </span>
              </div>
              <div className="w-1/5 flex justify-end items-center h-[60]">
                <button onClick={handleMypageBtn}>
                  <img src={MyPageImg} alt="메뉴" />
                </button>
              </div>
            </div>
          </nav>
        </header>

        <div>
          {data !== null ? (
            <>
              <div className=" relative pb-[50px] pt-[25px] mx-3">
                <Swiper
                  spaceBetween={1}
                  slidesPerView={1}
                  centeredSlides={true}
                  onActiveIndexChange={(e) => handleSwiperChange(e)}
                  pagination={{ el: ".custom-pagination", clickable: true }}
                  modules={[Pagination]}
                  className="relative"
                >
                  {data.map((item, idx) => (
                    <SwiperSlide key={idx}>
                      <div className=" mx-6 bg-white shadow-product">
                        <figure className="flex justify-center h-[400px]">
                          <img
                            src={item.img_url}
                            alt={`${item.name}`}
                            className="object-contain w-full max-w-[400px] h-auto"
                          />
                        </figure>
                        <div className="flex flex-col items-center text-center bg-white pb-8">
                          <div className="text-[16px] font-semibold mt-2">
                            <p>{item.name}</p>
                          </div>
                          <div className="text-Gray50 mb-1">
                            <p>{item.name_eng}</p>
                          </div>
                          <div className="my-3">
                            <p>
                              <span className="font-bold text-[18px]">
                                ${item.price_member}
                              </span>
                              <span className="ml-2 text-Gray50">
                                (
                                {(
                                  item?.price_member * exchangeRate
                                ).toLocaleString()}{" "}
                                원)
                              </span>
                            </p>
                          </div>
                          <div className="text-[15px] font-semibold bg-backgroundGray w-fit py-2 px-3">
                            <span>총 {item.reg_stock}병 수량 중</span> &nbsp;
                            <span className="text-Aqua">
                              {item.tot_stock ? item.tot_stock : 0}병 남음
                            </span>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
                <div className="custom-pagination mt-5 flex justify-center"></div>
              </div>

              <div className="mb-6 mx-6 items-center ">
                <button
                  onClick={() => handlePurchase(id)}
                  className="bg-black text-white font-semibold py-3 px-12 w-full"
                >
                  상품보기
                </button>
              </div>

              {isAlertModalOpen && <ListModal onClose={ModalHandler} />}
            </>
          ) : null}
        </div>
      </div>
    </main>
  );
};
