import { useLocation, useNavigate } from "react-router";
import { useEffect, useState } from "react";
import Camera from "./img/camera.svg";
import { WhiskyLayout } from "./WhiskyLayout";
import imageCompression from "browser-image-compression";

import { client } from "../../client";
import { CREATE_REVIEW } from "./Query/Whisky.gql";

import AWS from "aws-sdk";
import { jwtDecode } from "jwt-decode";

export const Feedback = () => {
  const [rating, setRating] = useState(3);
  const [hover, setHover] = useState(3);

  const [qualityBtnValue, setQualityBtnValue] = useState(2);
  const [repurchaseBtnValue, setRepurchaseBtnValue] = useState(2);
  const [textLength, setTextLength] = useState(0);
  const [textContents, setTextContents] = useState(null);
  const [attachedImages, setAttachedImages] = useState([]);
  const [resizedImages, setResizedImages] = useState([]);
  const [userId, setUserId] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();

  const data = location.state.data;

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  };

  useEffect(() => {
    const tokenFromCookie = getCookie("accessToken");
    if (tokenFromCookie) {
      localStorage.setItem("accessToken", tokenFromCookie);
    }
    const token = localStorage.getItem("accessToken");

    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setUserId(decodedToken.id);
      } catch (error) {
        console.error("Failed to decode token", error);
      }
    }
  }, []);

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];

    if (!file) return;

    const resizedImage = await resizeImage(file);

    handleFilePreview(resizedImage);

    const fileName = encodeURIComponent(resizedImage.name);
    const fileType = resizedImage.type;

    try {
      const presignedUrl = await generatePresignedUrl(fileName, fileType);

      const uploadResponse = await fetch(presignedUrl, {
        method: "PUT",
        headers: {
          "Content-Type": fileType,
        },
        body: resizedImage,
      });

      if (uploadResponse.ok) {
        console.log("File successfully uploaded!", presignedUrl.split("?")[0]);
        setResizedImages((prevImages) => [
          ...prevImages,
          presignedUrl.split("?")[0],
        ]);
      } else {
        console.error("File upload failed:", uploadResponse.statusText);
      }
    } catch (err) {
      console.error("Error during file upload:", err);
    }
  };

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_AWS_REGION,
  });

  const handleFilePreview = (file) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      setAttachedImages((prevImages) => [...prevImages, reader.result]);
    };

    reader.readAsDataURL(file);
  };

  const generatePresignedUrl = async (fileName, fileType) => {
    const s3 = new AWS.S3();
    const params = {
      Bucket: "dfx-img",
      Key: fileName,
      Expires: 60,
      ContentType: fileType,
    };

    try {
      const url = await s3.getSignedUrlPromise("putObject", params);
      return url;
    } catch (err) {
      console.error("Error generating presigned URL", err);
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const handleSubmit = async () => {
    const variables = {
      productID: parseInt(data.id),
      userID: parseInt(userId),
      rating: rating,
      comment: textContents,
      quality_score: qualityBtnValue,
      repurchase_score: repurchaseBtnValue,
      photo_urls: resizedImages ? resizedImages : [],
    };

    try {
      const res = await client.mutate({
        mutation: CREATE_REVIEW,
        variables: variables,
        fetchPolicy: "no-cache",
      });

      if (res) {
        alert("리뷰가 성공적으로 등록되었습니다.");
        navigate(-1);
      }
    } catch (err) {
      console.error(err);
      alert("리뷰 등록에 실패했습니다. 다시 시도해주세요.");
    }
  };

  const qualityButtons = [
    { value: 1, label: "별로예요" },
    { value: 2, label: "보통이에요" },
    { value: 3, label: "최고예요" },
  ];

  const repurchaseButtons = [
    { value: 1, label: "없어요" },
    { value: 2, label: "보통이에요" },
    { value: 3, label: "있어요" },
  ];

  const reviewContents = (e) => {
    let contents = e.target.value;
    setTextContents(contents);
    setTextLength(Array.from(contents).length);
  };

  const handleRemoveImage = (index) => {
    setAttachedImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const resizeImage = async (file) => {
    const options = {
      maxSizeMB: 3,
      maxWidthOrHeight: 900,
      useWebWorker: true,
    };

    try {
      const compressedBlob = await imageCompression(file, options);
      const compressedFile = new File([compressedBlob], file.name, {
        type: file.type,
      });
      return compressedFile;
    } catch (error) {
      console.error("Error resizing image:", error);
      return null;
    }
  };

  return (
    <WhiskyLayout pageName={"리뷰작성"} menu={false} iframe={null}>
      <section>
        <article className="h-[96px] w-full flex justify-center items-center">
          <div className="w-1/4 flex justify-center items-center">
            <img
              src={data.img_url}
              alt={data.name}
              className="w-[70px] h-[70px]"
            />
          </div>
          <div className="h-[80px] w-3/4">
            <figure className="min-w-[250px]">
              <h1 className="text-Gray70 text-[13px] my-2 leading-[15px]">
                {data.name_eng}
              </h1>
              <h1 className="font-normal text-[14px] leading-[16px]">
                {data.name}
              </h1>
            </figure>
          </div>
        </article>
      </section>
      <div className="h-[10px] bg-DividerGray"></div>
      <section className="px-3">
        <article className="mt-[24px]">
          <h1 className="font-bold text-[16px] leading-[19px]">
            상품은 어떠셨나요?
          </h1>
          <figure className="h-[130px] flex justify-center">
            {[...Array(5)].map((star, index) => {
              index += 1;
              return (
                <button
                  type="button"
                  key={index}
                  className={
                    index <= (hover || rating)
                      ? "text-yellow-500 flex justify-center items-start pt-2"
                      : "text-Gray30 flex items-start justify-center pt-2"
                  }
                  onClick={() => setRating(index)}
                  onMouseEnter={() => setHover(index)}
                  onMouseLeave={() => setHover(rating)}
                >
                  <span className="text-[40px] block">★</span>
                </button>
              );
            })}
          </figure>
        </article>

        <article>
          <h1 className="font-bold text-[16px] leading-[19px] mb-[12px]">
            품질은 어떤가요?
          </h1>
          <figure className="w-full h-[50px]">
            {qualityButtons.map((button) => (
              <button
                key={button.value}
                className={`w-1/3 h-full ${
                  qualityBtnValue === button.value
                    ? "bg-black text-white text-[14px]"
                    : "border-[1px] border-BorderGrayD9 text-[14px]"
                }`}
                value={button.value}
                onClick={() => setQualityBtnValue(button.value)}
              >
                {button.label}
              </button>
            ))}
          </figure>
        </article>
        <article>
          <h1 className="font-bold text-[16px] leading-[19px] mb-[12px] mt-[40px]">
            재구매 의사가 있나요?
          </h1>
          <figure className="w-full h-[50px]">
            {repurchaseButtons.map((button) => (
              <button
                key={button.value}
                className={`w-1/3 h-full ${
                  repurchaseBtnValue === button.value
                    ? "bg-black text-white text-[14px]"
                    : "border-[1px] border-BorderGrayD9 text-[14px]"
                }`}
                value={button.value}
                onClick={() => setRepurchaseBtnValue(button.value)}
              >
                {button.label}
              </button>
            ))}
          </figure>
        </article>

        <article className="h-[200px] mb-[40px]">
          <h1 className="font-bold text-[16px] leading-[19px] mb-[12px] mt-[40px]">
            어떤 점이 좋았나요?
          </h1>
          <form className="w-full h-[200px]">
            <textarea
              id="review"
              name="review"
              required
              minLength="10"
              maxLength="1000"
              className="border border-BorderGrayD9 text-[14px] leading-[16px] font-normal p-4 w-full min-h-[150px]"
              rows="4"
              placeholder="리뷰내용은 최소 10자 이상 적어주세요"
              onChange={(e) => reviewContents(e)}
            ></textarea>
            <p className="font-normal text-[13px] flex justify-end">
              {textLength}
              <span className="text-Gray50">/1000</span>
            </p>
          </form>
        </article>

        <article className="mb-[40px]">
          <h1 className="font-bold text-[16px] leading-[19px] mb-[12px] mt-[40px]">
            사진 첨부
          </h1>
          <div className="flex items-center">
            <figure className="w-[70px] h-[70px] flex items-center justify-center border-[1px] border-solid border-BorderGrayD9">
              <label className="w-full h-full flex items-center justify-center cursor-pointer">
                <input
                  type="file"
                  accept="image/png, image/jpg"
                  onChange={handleFileUpload}
                  className="hidden"
                />
                <img src={Camera} alt="사진 첨부" />
              </label>
            </figure>

            {attachedImages &&
              attachedImages.map((image, index) => (
                <figure
                  key={index}
                  className="w-[70px] h-[70px] flex items-center justify-center border-[1px] border-solid border-BorderGrayD9 mx-[5px] relative"
                >
                  <img
                    src={image}
                    alt={`첨부된 사진 ${index + 1}`}
                    className="w-[70px] h-[70px]"
                  />
                  <button
                    className="absolute top-0 right-0"
                    onClick={() => handleRemoveImage(index)}
                  >
                    x
                  </button>
                </figure>
              ))}
          </div>
        </article>
      </section>

      <section className="flex items-center w-full h-[52px]">
        <button
          onClick={() => {
            navigate(-1);
          }}
          className="w-1/2 bg-Gray50 text-white h-full"
        >
          취소
        </button>
        <button
          onClick={handleSubmit}
          className="w-1/2 bg-black text-white h-full"
        >
          등록
        </button>
      </section>
    </WhiskyLayout>
  );
};
