import { useState } from "react";

export const DisplayPickupInfoModal = ({ onClose }) => {
  const locationList = [
    {
      locationName: "인천국제공항 T1 서편 인도장",
      img_url: "https://placehold.co/320x320",
      ref_number: "032-743-6507",
      partner_company: "신라아이파크면세점",
    },
    {
      locationName: "김해공항 인도장",
      img_url: "https://placehold.co/320x320",
      ref_number: "051-974-3771",
      partner_company: "롯데면세점",
    },
    {
      locationName: "인천국제공항 T2 인도장",
      img_url: "https://placehold.co/320x320",
      ref_number: "032-743-6508",
      partner_company: "신라아이파크면세점",
    },
  ];

  const [selectedLocation, setSelectedLocation] = useState(locationList[0]);

  return (
    <section className="z-20 left-0 right-0 bottom-0 font-CustomSans fixed flex items-center justify-center w-full min-w-[320px]">
      <div className="min-h-[400px] bg-white z-50 rounded-t-[20px] shadow-lg relative max-w-[405px]">
        <div className="px-8">
          <h1 className="text-[18px] font-bold leading-[21px] text-center mt-[40px] py-1">
            인도장 위치 안내
          </h1>

          <div className="bg-BorderGray w-full h-[1px] my-3"></div>
          <div className="mt-[25px] text-center">
            <span className="text-[16px] leading-[23px] font-bold text-pretty">
              [상품수령] {selectedLocation.locationName}
            </span>
          </div>

          <div className="text-center mb-[30px] mt-[12px] text-[14px] leading-[20px] font-normal text-black">
            당사 파트너사인{" "}
            <span className="font-bold">
              '{selectedLocation.partner_company}'
            </span>{" "}
            <br /> 카운터에서 상품 수령하실 수 있습니다.
            <span className="py-1 flex justify-center">
              (인도장 제일 끝으로 오세요!)
            </span>
          </div>

          <div className="mt-[25px] text-center">
            <select
              value={selectedLocation.locationName}
              onChange={(e) =>
                setSelectedLocation(
                  locationList.find(
                    (location) => location.locationName === e.target.value
                  )
                )
              }
              className="text-[14px] px-2 py-3 border border-BorderGray w-full"
            >
              {locationList.map((location, index) => (
                <option key={index} value={location.locationName}>
                  {location.locationName}
                </option>
              ))}
            </select>
          </div>

          <div className="my-[15px]">
            <img
              src={selectedLocation.img_url}
              alt={selectedLocation.locationName}
            />
          </div>

          <div className="flex justify-center text-[14px] mb-[35px]">
            <span>인도장 문의 안내번호 : {selectedLocation.ref_number}</span>
          </div>
        </div>

        <div className="w-full max-h-[50px] flex flex-col items-center">
          <button
            onClick={() => onClose()}
            className="bg-black text-white font-semibold py-3 px-12 w-full sm:min-w-[360px]"
          >
            확인
          </button>
        </div>
      </div>

      <div
        className="fixed inset-0 bg-black opacity-50 z-8"
        onClick={onClose}
      ></div>
    </section>
  );
};
